import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import he from 'he';

export default class MobileCards extends Component {
  state = {
    imageLoaded: false,
  }

  handleImageLoaded = (image) => {
    if(image.includes('https')) {
      this.setState({ imageLoaded: true });
    } else {
      this.setState({ imageLoaded: false });
    }
  };

  render() {
    const s = require('./mobile.module.scss');
    const { imageLoaded } = this.state;
    const { images, date, title, author, blurb, post_blurb, location, button } = this.props;
    const newImage = images && images.large;
    const decodedHtml = blurb ? he.decode(blurb) : he.decode(post_blurb || '');
    return (
      <Link href={location === '/category/catalogues' ? `/catalogue${button.link}` : `/article${button.link}`} passHref>
        <a className={s.Wrapper}>
          <div className={s.Container}>
            {images &&
              <div className={s.Image} style={{ background: `url(${images && images.large}) no-repeat` }}>
                <Image
                  src={newImage}
                  alt={title}
                  width= '100%'
                  height= '100%'
                  style={{ opacity: 0 }}
                  onLoad={() => this.handleImageLoaded(newImage)}
                />
              </div>
            }
            <div className={s.Content}>
              <div className={s.Title}>
                <h1>{title}</h1>
              </div>
              <div className={s.Category}>
                <div>{date}</div>
                <p>{author}</p>
              </div>
              {post_blurb && <div
                className={s.Paragraph}
                dangerouslySetInnerHTML={{ __html: post_blurb }}
              />}
              {blurb && <div className={s.Paragraph} dangerouslySetInnerHTML={{ __html: decodedHtml }}></div>}
            </div>
          </div>
        </a>
      </Link>
    );
  }
}

MobileCards.propTypes = {
  images: PropTypes.object,
  date: PropTypes.string,
  title: PropTypes.string,
  author: PropTypes.string,
  post_blurb: PropTypes.string,
  blurb: PropTypes.string,
  location: PropTypes.string,
  button: PropTypes.object,
};